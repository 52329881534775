@import './variables';

.d-flex{
    display: flex;
}

.w-100{
    width: 100%;
}

.justify-space-between{
    justify-content: space-between;
}

.justify-content-start {
    justify-content: flex-start !important;
}
.flex-column{
    flex-direction: column;
}
.flex-row{
    flex-direction: row;
}
.ms-1 {
    margin-left: 0.5rem;
}

.m-1{
    margin: 1rem;
}

.m-2{
    margin: 2rem;
}

.m-3{
    margin: 3rem;
}

.py{
padding-top: 0.2rem;
padding-bottom: 0.2rem;
}
.py-1{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;  
}
.px-1{
padding-left: 0.5rem;
padding-right: 0.5rem;
}
.p-1 {
    padding: 0.5rem !important;
}

.p-2 {
    padding: 1rem;
}

.p-3 {
    padding: 2rem;
}

.mx-1{
   margin: 0 1rem;
}

.mx-2{
    margin: 0 2rem;
}

.mx-3{
    margin: 0 3rem;
}
.my-1{
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.mb-2 {
    margin-bottom: 2rem;
}

.align-items-center {
    align-items: center;
}

.label-form {
    margin-bottom: 0.5rem;
    display: block;
}

.primary-icon-color {
    color: #2974F0;   
}

.position-relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer;
}

.text-success {
    color: $text-success-color;
}

.text-danger {
    color: $text-danger-color;
}
.text-muted{
color: $text-muted;
}
.border-b{
    border-bottom: 1px solid #e0e0e0;
}
.border{
    border:  1px solid rgb(136, 136, 136) !important;
}
.bg-blue{
background-color: #2974F0 !important;
}
.primaryGreen{
    color: #05c9ab;
}